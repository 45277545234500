import React, { useEffect, useState } from "react";
import FightsDiagramContainer from "../FightsDiagram/FightsDiagramContainer";
import s from "./FightsGrid.module.sass";
import "./FightIntervalStyle.css";
import ChangeCategoryContainer from "../ChangeCategory/ChangeCategoryContainer";
import {
  Alert,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Select,
  MenuItem,
  Box,
  Snackbar,
  FormControl,
  InputLabel,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import FormControlLabel from "@mui/material/FormControlLabel";
import ControlledModal from "../ControlledModal/ControlledModal";
import Loading from "../Loading/Loading";
import CustomCheckbox from "../CustomCheckbox/CustomCheckbox";
import { getCookie } from "../../AppExtComponents.ts";
import useModalStatus_deepCopy from "../../hooks/useModalStatus_deepCopy";
import BasketFilter from "../filters/BasketFilter";
import SettingsIcon from "@mui/icons-material/Settings";
import ReduceCapacityIcon from "@mui/icons-material/ReduceCapacity";
import SafetyDividerIcon from "@mui/icons-material/SafetyDivider";
import ChangeFightContainer from "../ChangeFight/ChangeFightContainer";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import Looks3Icon from "@mui/icons-material/Looks3";
import GroupsIcon from "@mui/icons-material/Groups";
import { EventSystem } from "../../constants";
import CustomSelect from "../CustomSelect/CustomSelect";
import { basketAPI } from "../../api/basketAPI";
import { Text } from "../../language/lang";
import { useSelector } from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import { getEventState } from "../../redux/selectors/getEventState";

const FightsGrid = ({
  grids = [],
  allGrids = [],
  eventId,
  fetching,
  getData,
  countOfCategories,
  downloadFile,
  eventSystem,
  platformsNumber,
}) => {
  const selectGrid = useSelector((state) => state.fight.selectGrid);
  const selectGridBasketFilter = useSelector(
    (state) => state.fight.selectGridBasket
  );

  const { event } = useSelector(getEventState);

  const ModalName = {
    CHANGE_CATEGORY: "changeCategory",
    CHANGE_FIGHT: "changeFight",
    DIPLOMAS: "diplomas",
    SETTINGS: "settings",
  };
  const SettingType = {
    BASKET: 0,
    FIGHT: 1,
  };
  const Places = {
    first: <LooksOneIcon />,
    second: <LooksTwoIcon />,
    third: <Looks3Icon />,
  };
  const [isModalOpen, setModalOpen, resetModalOpen] = useModalStatus_deepCopy({
    [ModalName.CHANGE_CATEGORY]: false,
    [ModalName.CHANGE_FIGHT]: false,
    [ModalName.DIPLOMAS]: false,
    [ModalName.SETTINGS]: false,
  });
  const [transferData, setTransferData] = useState({
    name: null,
    users: [],
    grid: null,
  });
  let countWeightCategory = [];
  const [isHideEmptyGrids, setHideEmptyGrids] = useState(true);

  const [basketParams, setBasketParams] = useState({
    isOpen: false,
    basket: {
      name: null,
      fights: [],
      result: [],
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState("");
  const availableGrids = getAvailableGrids();
  const [selectedItems, setSelectedItems] = useState([]);
  const [changePlatform, setChangePlatform] = useState();
  const [changeState, setChangeState] = useState();
  const [date, setDate] = useState();
  const [interval, setInterval] = useState();
  const [alertInfo, setAlertInfo] = useState();
  useEffect(() => {
    setAlertInfo(selectedItems.length > 0 ? true : false);
  }, [selectedItems]);

  const handleOpen = ({ name, users, grid, modalName }) => {
    return () => {
      setTransferData({ name, users, grid });
      if (modalName)
        setModalOpen({
          [modalName]: true,
        });
    };
  };

  const handleChooseSettings = (transferType) => {
    return () => {
      if (transferType === SettingType.BASKET) {
        setModalOpen({
          [ModalName.SETTINGS]: false,
          [ModalName.CHANGE_CATEGORY]: true,
        });
      }
      if (transferType === SettingType.FIGHT) {
        setModalOpen({
          [ModalName.SETTINGS]: false,
          [ModalName.CHANGE_FIGHT]: true,
        });
      }
    };
  };

  const handleClose = () => {
    resetModalOpen();
    getData();
  };

  function getAvailableGrids() {
    const gridsNames = [];
    allGrids.forEach((discipline) =>
      discipline.baskets.forEach((basket) => {
        if (!basket.is_blocked) gridsNames.push(basket?.name);
      })
    );
    return gridsNames;
  }

  const getGridsWithoutCurrent = ({ gridsNames, currentGrid }) => {
    return gridsNames.filter((name) => name !== currentGrid);
  };

  const hideEmptyGrids = (e) => {
    setHideEmptyGrids(e.target.checked);
  };

  const providePropsToOpenBasket = ({ name, fights, result, users }) => {
    return () => {
      setBasketParams({
        isOpen: true,
        basket: {
          name,
          fights,
          result,
          users,
        },
      });
    };
  };
  const handleCloseBasket = () => {
    setBasketParams({
      isOpen: false,
      basket: {
        name: null,
        fights: [],
        result: [],
      },
    });
  };
  const dataFilterIndex = () => {
    let indexs = [];
    let dataWeightCategory = [];
    grids?.map((discipline, indexGrids) =>
      discipline.baskets.map((grid, indexGrid) => {
        const name = grid.name.split(";");
        const countParticipants = grid.count;
        dataWeightCategory.push({ count: countParticipants, name: name });

        if (grid.count === 0 && isHideEmptyGrids) return null;
        else if (selectGrid === 0) indexs.push([indexGrids, indexGrid]);
        else if (selectGrid === 1 && grid.is_blocked !== 0)
          indexs.push([indexGrids, indexGrid]);
        else if (selectGrid === 2 && grid.is_blocked === 0)
          indexs.push([indexGrids, indexGrid]);
      })
    );
    countWeightCategory = dataWeightCategory;
    if (selectGridBasketFilter === -2) {
      indexs = indexs.reverse();
    }
    return indexs;
  };

  function checkboxHandler(e) {
    let isSelected = e.target.checked;
    let value = parseInt(e.target.value);

    if (isSelected) {
      setSelectedItems([...selectedItems, value]);
    } else {
      setSelectedItems((prevData) => {
        return prevData.filter((id) => {
          return id !== value;
        });
      });
    }
  }
  function checkAllHandlerMain(event) {
    if (dataFilterIndex().length === selectedItems.length) {
      setSelectedItems([]);
    } else {
      const postIds = dataFilterIndex().map((item, i) => {
        return i;
      });

      setSelectedItems(postIds);
    }
  }

  const handleSetBasketPlatformSetting = (platform) => {
    let data = [
      {
        id: eventId,
        basket: transferData.name,
        platform: platform,
        stage: "",
        interval: "",
      },
    ];
    handleSetBasketPlatform(data);
  };
  const handleSetBasketPlatform = async (value) => {
    setIsLoading(true);
    try {
      await basketAPI.updateBasketPlatform(value);
      setNotification("Готово");
    } catch (error) {
      setNotification(new Error("Ошибка"));
    }
    setIsLoading(false);
  };

  const getItems = () => {
    let rangeDate = [];

    if (Number(event.date_to) < Number(event.date_from) + 24 * 60 * 60 * 1000) {
      rangeDate.push(
        new Date(Number(event.date_from)).toISOString().substr(0, 10)
      );
    } else
      for (
        let i = Number(event.date_from);
        i <= Number(event.date_to);
        i = i + 24 * 60 * 60 * 1000
      ) {
        rangeDate.push(new Date(i).toISOString().substr(0, 10));
      }
    return rangeDate.map((elem) => elem.split("-").reverse().join("."));
  };

  const handleSetBasketPlatformStageSave = async () => {
    const data = [];

    dataFilterIndex().forEach((indexGrid, i) => {
      for (let j = 0; j < selectedItems.length; j++) {
        if (i === selectedItems[j]) {
          data.push({
            id: eventId,
            basket: grids[indexGrid[0]].baskets[indexGrid[1]].name,
            platform: changePlatform,
            stage: changeState,
            date: date,
            interval: interval,
          });
        }
      }
    });
    console.log(data);
    if (data.length > 0) {
      handleSetBasketPlatform(data);
      setSelectedItems([]);
      setTimeout(() => getData(), 50);
    }
  };
  const outPlatform = () => {
    let platforms = [];
    for (let i = 1; i <= Number(platformsNumber); i++) {
      platforms.push(
        <MenuItem key={"MenuOutPlatform" + i} value={i}>
          {i}
        </MenuItem>
      );
    }
    return platforms;
  };

  return (
    <>
      <Snackbar
        open={Boolean(notification)}
        autoHideDuration={3000}
        onClose={() => setNotification("")}
      >
        <Alert severity="info">{notification}</Alert>
      </Snackbar>

      <Loading isLoading={fetching.isLoading || fetching.gettingEvent} />
      {grids.length !== 0 && (
        <div className={s.wrapper}>
          <Box className={`${s.filter} ${s.filter_desktop}`}>
            {window.innerWidth >= 768 && (
              <Box sx={{ position: "sticky" }}>
                <BasketFilter isExpanded />
              </Box>
            )}
          </Box>
          <div className={s.gridsWrapper}>
            <h3>{Text.FightsGrid.GridsCategories}</h3>
            <div className={`${s.filter} ${s.filter_mobile}`}>
              {window.innerWidth < 768 && <BasketFilter />}
            </div>
            <div className={s.subinfo}>
              <CustomCheckbox
                label={Text.FightsGrid.HideEmptyGrids}
                onChange={hideEmptyGrids}
                defaultChecked
              />

              <div className={s.filterSelect}>
                <CustomSelect
                  name={"countrymen"}
                  items={[
                    { name: Text.FightsGrid.Items.All, value: 0 },
                    { name: Text.FightsGrid.Items.Past, value: 1 },
                    { name: Text.FightsGrid.Items.Failed, value: 2 },
                  ]}
                  label={Text.FightsGrid.ShowGrids}
                />
              </div>

              <div className={s.counts}>
                <span>
                  {Text.FightsGrid.NumberEmptyGrids}
                  <span className={s.counts__number}>
                    {countOfCategories.baskets}
                  </span>
                </span>
                <span>{Text.FightsGrid.NumberPrizePlaces}</span>
                <div className={s.counts__places}>
                  {Object.entries(
                    countOfCategories?.places ? countOfCategories?.places : []
                  ).map((ent) => {
                    return (
                      <span key={ent[0]} className={s.counts__number}>
                        {Places[ent[0]]}: {ent[1]}
                      </span>
                    );
                  })}
                </div>
              </div>
            </div>
            {getCookie("admin") && (
              <div>
                <Alert
                  sx={{
                    display: alertInfo ? "none" : "flex",
                    marginBottom: "10px",
                  }}
                  severity="info"
                >
                  Выберите сетку для изменения (нажмите на галочку справа у
                  строки таблицы)
                </Alert>
                <div className={s.settingPlatformAndStage}>
                  <div className={s.customSelectSetting}>
                    <Box className={s.select}>
                      <FormControl
                        disabled={!alertInfo}
                        sx={{ width: "135px" }}
                      >
                        <InputLabel id="demo-simple-select-label">
                          Площадка №
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Площадка №"
                          onChange={(event) => {
                            setChangePlatform(event.target.value);
                          }}
                        >
                          {outPlatform()}
                        </Select>
                      </FormControl>

                      <FormControl
                        disabled={!alertInfo}
                        sx={{ width: "135px" }}
                      >
                        <InputLabel id="demo-simple-select-label">
                          Этап №
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Этап №"
                          onChange={(event) => {
                            setChangeState(event.target.value);
                          }}
                        >
                          {Array.from({ length: 10 }, (s, i) => (
                            <MenuItem key={i + 1 + "menuFight"} value={i + 1}>
                              {i + 1}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <FormControl
                        disabled={!alertInfo}
                        sx={{ width: "135px" }}
                      >
                        <InputLabel id="demo-simple-select-label">
                          Дата
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Дата"
                          onChange={(event) => {
                            setDate(event.target.value);
                          }}
                        >
                          {getItems().map((elemData, index) => (
                            <MenuItem key={elemData} value={elemData}>
                              {elemData}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          disabled={!alertInfo}
                          sx={{ width: "130px" }}
                          label="Интервал"
                          className="time"
                          onChange={(event) => {
                            setInterval(event.$d.toISOString().slice(14, 19));
                          }}
                          views={["minutes", "seconds"]}
                          format="mm:ss"
                        />
                      </LocalizationProvider>
                    </Box>

                    <FormControlLabel
                      label="Выбрать всех"
                      control={
                        <Checkbox
                          onChange={checkAllHandlerMain}
                          indeterminate={
                            selectedItems.length > 0 &&
                            selectedItems.length !== dataFilterIndex().length
                          }
                          checked={
                            selectedItems.length === dataFilterIndex().length
                          }
                          sx={{
                            "& .MuiSvgIcon-root": { fontSize: 41 },
                            padding: "0px",
                            marginLeft: "10px",
                          }}
                        />
                      }
                    />
                  </div>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",

                      gap: "10px",
                    }}
                  >
                    <Button
                      variant="outlined"
                      style={{ borderRadius: "10px" }}
                      size="large"
                      color="secondary"
                      onClick={handleSetBasketPlatformStageSave}
                    >
                      Сохранить
                    </Button>
                    <Button
                      variant="outlined"
                      style={{ borderRadius: "10px" }}
                      size="large"
                      color="secondary"
                      // onClick={handleSetBasketPlatformStageSave}
                    >
                      Утвердить
                    </Button>
                  </Box>
                </div>
              </div>
            )}

            <Box className={s.grids}>
              {dataFilterIndex().map((elem, i) => {
                const grid = grids[elem[0]].baskets[elem[1]];
                // if (
                //   !grid?.is_blocked ||
                //   grid?.count !== 0 ||
                //   !grid?.is_closed
                // ) {
                //   console.log(grid);
                // }

                return (
                  <Box key={i} className={s.grids__item}>
                    <span
                      className={s.grids__name}
                      onClick={providePropsToOpenBasket({
                        name: grid?.name,
                        fights: grid?.fights,
                        result: grid?.results,
                        users: grid?.users,
                      })}
                    >
                      {grid?.name}
                    </span>
                    <div className={s.grids__info}>
                      <div className={s.grids__platformStage}>
                        <div>{"Пл." + grid?.platform}</div>
                        {getCookie("admin") && (
                          <div>{"Этап." + grid?.stage}</div>
                        )}
                      </div>

                      <div className={s.grids__count}>
                        <GroupsIcon />

                        <span>{grid?.count}</span>
                      </div>

                      {getCookie("admin") && (
                        <>
                          {grid?.is_blocked ||
                          grid?.count === 0 ||
                          grid?.is_closed ? (
                            <IconButton disabled>
                              <SettingsIcon />
                            </IconButton>
                          ) : (
                            <IconButton
                              onClick={handleOpen({
                                name: grid?.name,
                                users: grid?.users,
                                grid: grid,
                                modalName: ModalName.SETTINGS,
                              })}
                            >
                              <SettingsIcon />
                            </IconButton>
                          )}

                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selectedItems.includes(i)}
                                value={i}
                                onChange={checkboxHandler}
                              />
                            }
                          />
                        </>
                      )}
                    </div>
                  </Box>
                );
              })}
            </Box>

            <FightsDiagramContainer
              name={basketParams.basket.name}
              result={basketParams.basket.result}
              fights={basketParams.basket.fights}
              users={basketParams.basket.users}
              isOpen={basketParams.isOpen}
              onClose={handleCloseBasket}
            />
            <ControlledModal
              isOpen={isModalOpen[ModalName.SETTINGS]}
              onClose={handleClose}
              title={<h3>Настройки</h3>}
            >
              <Loading isLoading={isLoading} />
              <List>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={handleChooseSettings(SettingType.BASKET)}
                  >
                    <ListItemIcon>
                      <ReduceCapacityIcon />
                    </ListItemIcon>
                    <ListItemText primary="Перенос между сетками" />
                  </ListItemButton>
                </ListItem>
                {eventSystem === EventSystem.OLYMPLIC && (
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={handleChooseSettings(SettingType.FIGHT)}
                    >
                      <ListItemIcon>
                        <SafetyDividerIcon />
                      </ListItemIcon>
                      <ListItemText primary="Перенос между поединками" />
                    </ListItemButton>
                  </ListItem>
                )}
                <ListItem disablePadding>
                  <CustomSelect
                    label="Номер площадки"
                    value={transferData.grid?.platform}
                    items={Array.from(Array(platformsNumber || 0).keys()).map(
                      (platform) => ({
                        value: platform + 1,
                        name: "Площадка №" + (platform + 1),
                      })
                    )}
                    onChange={handleSetBasketPlatformSetting}
                  />
                </ListItem>
              </List>
            </ControlledModal>
            <ControlledModal
              isOpen={isModalOpen[ModalName.CHANGE_CATEGORY]}
              onClose={handleClose}
              title={<h4>{transferData.name}</h4>}
            >
              <ChangeCategoryContainer
                eventId={eventId}
                name={transferData.name}
                availableGrids={getGridsWithoutCurrent({
                  currentGrid: transferData.name,
                  gridsNames: availableGrids,
                })}
                countWeightCategory={countWeightCategory}
              />
            </ControlledModal>
            <ControlledModal
              isOpen={isModalOpen[ModalName.CHANGE_FIGHT]}
              onClose={handleClose}
              title={<h4>{transferData.name}</h4>}
            >
              <ChangeFightContainer
                eventId={eventId}
                name={transferData.name}
                users={transferData.users}
              />
            </ControlledModal>
          </div>
        </div>
      )}
    </>
  );
};

export default FightsGrid;
