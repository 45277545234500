import { LoadingButton } from "@mui/lab";
import { Button, Paper, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { deleteCookie } from "../../AppExtComponents.ts";
import { Text } from "../../language/lang";
import s from "./Login.module.sass";

const Login = (props) => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (props.response.show) {
      enqueueSnackbar(props.response.text, {
        variant: props.response.ok ? "success" : "warning",
      });
      props.resetResponse();
    }
  }, [props.response]);

  const doRequest = (e) => {
    e.preventDefault();
    const data = Object.fromEntries(new FormData(e.target).entries());
    props.login(data);
    deleteCookie("admin");
    localStorage.removeItem("refreshToken");
    props.setUserID(-1);
  };

  return (
    <div className={s.login}>
      <Paper className={s.wrapper}>
        <form
          method="post"
          onSubmit={doRequest}
          className={s.form}
          encType="multipart/form-data"
        >
          <h3>{Text.Auth.LogInTitle}</h3>
          <TextField
            label="Email"
            variant="outlined"
            name="email"
            type="email"
            required
          />
          <TextField
            label={Text.Auth.Label.Password}
            variant="outlined"
            name="password"
            type="password"
            required
          />
          <LoadingButton
            loading={props.isFetching}
            variant="contained"
            type="submit"
          >
            {Text.Auth.LogIn}
          </LoadingButton>
          {props.children}
        </form>
      </Paper>
    </div>
  );
};

export default Login;
