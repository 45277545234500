import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Badge,
  Button,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  Paper,
  TextField,
} from "@mui/material";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { useSnackbar } from "notistack";
import _ from "underscore";
import MailIcon from "@mui/icons-material/Mail";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useState, useRef, useEffect } from "react";
import s from "./Manage.module.sass";
import { ModalWindow } from "../../../components/ModalWindow/ModalWindow";
import Divider from "@mui/material/Divider";
import { RequestsList } from "../../../components/RequestsList/RequestsList";
import { FinderList } from "../../../components/Club/ClubCreation/FinderList";
import { InsuranceCC } from "../../../components/Insurance/InsuranceCC";
import { nanoid } from "nanoid";
import { NavLink, useParams } from "react-router-dom";
import EastIcon from "@mui/icons-material/East";
import { getCookie } from "../../../AppExtComponents.ts";
import { Text } from "../../../language/lang";

export const Manage = (props) => {
  const [sportsmen, setSportsmen] = useState([]);
  const { userID } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const trainerID = useRef(-1);
  const clubID = useRef(-1);
  const sportsmanID = useRef(-1);
  const trainerRef = useRef(null);
  const clubRef = useRef();
  const userRef = useRef();
  let users;

  if (props.userData.confirmation !== undefined) {
    users = JSON.parse(props.userData.confirmation);
  }

  const requestToTrainer = () => {
    if (trainerID.current !== -1 && trainerRef.current.value !== "") {
      props.requestToTrainer({
        trainer_id: trainerID.current,
        user_id: props.userID,
      });
    } else {
      enqueueSnackbar(Text.User.ChooseTrainer, { variant: "warning" });
    }
  };

  const requestToClub = () => {
    if (clubID.current !== -1 && clubRef.current.value !== "") {
      if (props.userData.role === "manager")
        props.requestToClub({
          club_id: clubID.current,
          manager_id: props.userID,
        });
      if (props.userData.role === "trainer")
        props.requestToClub({
          club_id: clubID.current,
          trainer_id: props.userID,
        });
      if (
        props.userData.role === "sportsman" ||
        props.userData.role === "parent"
      )
        props.requestToClub({ club_id: clubID.current, user_id: props.userID });
      props.setForceRerender(true);
    } else {
      enqueueSnackbar(Text.User.ChooseClub, { variant: "warning" });
    }
  };

  const add = (id) => {
    props.addUserToTrainer({ trainer_id: props.userID, user_id: id });
  };

  const reject = (id) => {
    props.rejectUser({ trainer_id: props.userID, user_id: id });
  };

  const deleteAccount = () => {
    props.deleteUser({ id: props.userData.id });
  };

  const forceAddSportsmen = () => {
    if (userRef.current !== "") {
      props.addUserToTrainer({
        trainer_id: props.userID,
        user_id: sportsmanID.current,
      });
    } else {
      enqueueSnackbar(Text.User.ChooseAthlet, { variant: "warning" });
    }
  };

  const renderOption = (props, option) => {
    return (
      <li {...props} key={nanoid()}>{`${option.fio} - ${option.birth}`}</li>
    );
  };

  const handleSportsmanRegistration = () => {
    props.setIsSportsmanRegistration(true);
  };

  const handleSetShowEvents = () => {
    props.showEvents(true);
  };

  return (
    <Paper className={s.manage}>
      <div className={s.manage__buttons}>
        {/* Доступ запрещен, если аккаунт не подтвержден  */}
        {props.userData.role === "trainer" &&
          (Number(props.userData.status) !== 0 || props.isAdmin) && (
            <>
              <ModalWindow
                style={{ width: "100%" }}
                buttonStyle={{ width: "100%", height: "100%" }}
                title={<h3>{Text.User.ConfirmYourStudentAthletes}</h3>}
                button={
                  <Badge
                    badgeContent={users.length}
                    color="primary"
                    className={s.requestButton}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="secondary"
                      startIcon={<MailIcon color="action" />}
                    >
                      {Text.User.Requests}
                    </Button>
                  </Badge>
                }
              >
                <RequestsList requests={users} add={add} reject={reject} />
              </ModalWindow>
            </>
          )}

        {/* Доступ запрещен, если аккаунт не подтвержден  */}
        {(Number(props.userData.status) !== 0 || props.isAdmin) && (
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => props.setEditing(true)}
          >
            <EditRoundedIcon />
          </Button>
        )}

        {!props.isAdmin && (
          <LoadingButton
            loading={props.isFetchingAuth}
            variant="outlined"
            color="secondary"
            onClick={props.logout}
          >
            <LogoutRoundedIcon />
          </LoadingButton>
        )}

        <ModalWindow
          button={
            <Button variant="outlined" color="error">
              {Text.Base.Delete}
            </Button>
          }
          title={<h3>{Text.User.DeleteYourAccount}</h3>}
        >
          <>
            <span>{Text.User.AccountWillBeLost}</span>
            <div>
              <Button
                variant="outlined"
                color="error"
                onClick={deleteAccount}
                className={s.delButton}
              >
                {Text.Base.Delete}
              </Button>
            </div>
          </>
        </ModalWindow>

        <InsuranceCC>
          <Button variant="outlined" color="secondary">
            {Text.User.Insurance}
          </Button>
        </InsuranceCC>

        {((Number(props.userData.status) !== 0 &&
          props.userData.role === "trainer") ||
          (props.isAdmin && props.userData.role === "trainer")) && (
          <NavLink
            to={"/sign"}
            style={{ color: "#fad744", textDecoration: "none", height: "36px" }}
          >
            <Button
              variant="outlined"
              color="secondary"
              sx={{ fontSize: "11px", height: "100%" }}
              onClick={handleSportsmanRegistration}
            >
              {Text.User.RegStudent}
            </Button>
          </NavLink>
        )}
      </div>
      <Divider className={s.divider} orientation="vertical" flexItem />
      <div
        className={s.extraFunctions}
        style={{
          display: props.userData.role !== "director" ? "grid" : "none",
        }}
      >
        {/* Доступ запрещен, если аккаунт не подтвержден  */}
        {Number(props.userData.status) === 0 && (
          <span>{Text.User.ToGetAccessToFunctions}</span>
        )}
        {(Number(props.userData.status) !== 0 || props.isAdmin) && (
          <>
            {props.userData.role === "organizer" && (
              <Button
                variant="outlined"
                color="secondary"
                sx={{ height: "36px" }}
                onClick={handleSetShowEvents}
                endIcon={<EastIcon />}
              >
                {Text.User.MyCompetitions}
              </Button>
            )}
            {props.userData.role !== "manager" &&
              props.userData.role !== "trainer" && (
                <>
                  <div className={s.extraFunctions__search}>
                    <Autocomplete
                      disablePortal
                      options={props.trainers}
                      getOptionLabel={(option) => {
                        trainerID.current = option.id;
                        return `${option.firstname} ${option.secondname} ${option.thirdname}`;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          ref={trainerRef}
                          label={Text.User.SearchForTrainer}
                        />
                      )}
                    />
                    <LoadingButton
                      loading={props.isFetching.trainer}
                      variant="outlined"
                      color="secondary"
                      onClick={requestToTrainer}
                    >
                      {Text.User.SendRequest}
                    </LoadingButton>
                  </div>
                </>
              )}
            {props.userData.role !== "director" && (
              <>
                <div className={s.extraFunctions__search}>
                  <Autocomplete
                    disablePortal
                    options={props.clubs}
                    getOptionLabel={(option) => {
                      clubID.current = option.id;
                      return `${option.name} (${option.country}, ${option.city})`;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        ref={clubRef}
                        label={Text.User.SearchForClub}
                      />
                    )}
                  />
                  <LoadingButton
                    loading={props.isFetching.club}
                    disabled={props.userData.club !== ""}
                    variant="outlined"
                    color="secondary"
                    onClick={requestToClub}
                  >
                    {Text.User.SendRequest}
                  </LoadingButton>
                </div>
              </>
            )}
            {props.userData.role === "trainer" && (
              <>
                <div className={s.extraFunctions__search}>
                  <Autocomplete
                    disablePortal
                    options={props.sportsmenForTrainer}
                    getOptionLabel={(option) => {
                      sportsmanID.current = option.id;
                      return `${option.fio} - ${option.birth}`;
                    }}
                    renderOption={renderOption}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        ref={userRef}
                        label={Text.User.SearchForAthlet}
                      />
                    )}
                  />
                  <LoadingButton
                    loading={props.isFetching.trainer}
                    variant="outlined"
                    color="secondary"
                    onClick={forceAddSportsmen}
                  >
                    {Text.Base.Add}
                  </LoadingButton>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </Paper>
  );
};
