import {
  CircularProgress,
  IconButton,
  Paper,
  List,
  ListItem,
  Button,
  Chip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import s from "./User.module.sass";
import { useSnackbar } from "notistack";
import { Avatar } from "./Avatar/Avatar";
import { Info } from "./Info/Info";
import ManageCC from "./Manage/ManageCC";
import { parseJSON } from "../../helpers/parseJSON";
import { NavLink, useParams } from "react-router-dom";
import { EventRegistrationStatus } from "../../constants";
import { gr } from "../../AppExtComponents.ts";
import { nanoid } from "nanoid";
import { getCookie } from "../../AppExtComponents.ts";
import ActionLi from "../../components/ui/ActionLi";
import ControlledModal from "../../components/ControlledModal/ControlledModal";
import RegistrationPayment from "../../components/RegistrationPayment/RegistrationPayment";
import { useDispatch } from "react-redux";
import { setPaymentParams } from "../../redux/reducers/payment/payment";
import { getDateLegacy } from "../../helpers/getDateLegacy";
import getEventPrice from "../../helpers/getEventPrice";
import EventsHistory from "./EventsHistory/EventsHistory";
import { getUserStatus } from "../../helpers/getUserStatus";
import { Text } from "../../language/lang";
import { useModal } from "../../hooks/useModal";
import DocumentsUpdation from "../../components/DocumentsUpdation/DocumentsUpdation";

const LearnersList = (props) => {
  const users = props.users;

  const deleteLearner = (e) => {
    props.deleteLearner({
      trainer_id: props.userID,
      user_id: Number(e.currentTarget.dataset.index),
    });
  };

  return (
    <div style={{ display: "grid", gap: "8px" }}>
      {users.map((el, i) => {
        return (
          <ActionLi
            key={nanoid()}
            isNav
            to={"/user/" + el.id}
            endAction={
              props.isAuthorized && (
                <IconButton
                  edge="end"
                  aria-label="delete"
                  data-index={el.id}
                  onClick={deleteLearner}
                >
                  <CloseIcon />
                </IconButton>
              )
            }
          >
            {el.fio}
          </ActionLi>
        );
      })}
    </div>
  );
};

const User = (props) => {
  const data = props.userData;
  const [isOpenPayment, setOpenPayment] = useState(false);
  const {
    isOpen: isOpenDocuments,
    onOpen: handleOpenDocuments,
    onClose: handleCloseDocuments,
  } = useModal(false);
  const noDataPlaceholder =
    props.userID === -1 ? (
      <i>{Text.User.NoData}</i>
    ) : (
      <i style={{ color: "#c52c2f" }}>{Text.User.AddData}</i>
    );
  const learners =
    props.userData.users !== undefined ? JSON.parse(props.userData.users) : [];
  const { enqueueSnackbar } = useSnackbar();
  const { userID } = useParams();
  const dispatch = useDispatch();
  const isAuthorized = +userID === +getCookie("user");
  const isAdmin = Boolean(getCookie("admin"));

  useEffect(() => {
    if (
      (+props.userData.status === 2 || +props.userData.status === 3) &&
      !props.isDocsUpdated
    ) {
      handleOpenDocuments();
    }
    if (props.isDocsUpdated) {
      handleCloseDocuments();
    }
  }, [props.userData]);

  useEffect(() => {
    if (props.response.show) {
      enqueueSnackbar(props.response.text, {
        variant: props.response.ok ? "success" : "warning",
      });
      props.resetResponse();
    }
  }, [props.response]);

  const handleOpenPayment = ({ eventId, price, discount, disciplines }) => {
    return () => {
      dispatch(
        setPaymentParams({
          eventId,
          users: [{ id: userID, disciplines }],
          price,
          discount,
        })
      );
      setOpenPayment(true);
    };
  };

  const handleClosePayment = () => setOpenPayment(false);

  const getDebt = ({ price, discount, disciplines, paid }) => {
    return getEventPrice({ price, discount, disciplines }) - (paid ? paid : 0);
  };

  const getPaymentBlock = (event) => {
    const debt = getDebt({
      price: event?.price,
      discount: event?.discount,
      disciplines: event.disciplines,
      paid: event?.paid,
    });

    return (
      <div className={s.payment}>
        <Chip
          className={s.payment__debt}
          color="success"
          variant="outlined"
          label={
            debt !== 0
              ? `${Text.Payment.ToBePaid}: ${debt}`
              : Text.Payment.EverythingIsPaidFor
          }
        />

        <Button
          onClick={handleOpenPayment({
            eventId: event.id,
            price: debt,
            discount: event.discount,
            disciplines: event.disciplines,
          })}
          color="success"
          variant="contained"
          disabled={debt === 0}
        >
          {Text.Payment.Pay}
        </Button>
      </div>
    );
  };

  const getDocsUpdationText = () => {
    if (+props.userData.status === 2)
      return (
        <p className={s.updateDocs__text}>{Text.User.UpdateDocs[2].Body}</p>
      );
    if (+props.userData.status === 3)
      return (
        <p className={s.updateDocs__text}>{Text.User.UpdateDocs[3].Body}</p>
      );
    if (+props.userData.status === 4)
      return (
        <p className={s.updateDocs__text}>{Text.User.UpdateDocs[4].Body}</p>
      );
  };

  const getDocsUpdationTitle = () => {
    if (+props.userData.status === 2) return Text.User.UpdateDocs[2].Title;
    if (+props.userData.status === 3) return Text.User.UpdateDocs[3].Title;
    if (+props.userData.status === 4) return Text.User.UpdateDocs[4].Title;
  };

  return (
    <div className={s.userPage}>
      {props.isFetching.init ? (
        <CircularProgress
          style={{
            margin: "30vh auto",
          }}
        />
      ) : (
        <div
          className={
            s.wrapper +
            (isAuthorized || props.isAdmin ? "" : " " + s.wrapper_guest)
          }
        >
          <div className={s.wrapper__avatar}>
            <Avatar image={data.image} />
          </div>
          <div className={s.wrapper__info}>
            <Info
              userData={data}
              trainers={props.trainers}
              isAdmin={props.isAdmin}
              userID={userID}
            />
          </div>
          {(isAuthorized || props.isAdmin) && (
            <div className={s.wrapper__manage}>
              <ManageCC />
            </div>
          )}
          <div className={s.wrapper__about}>
            <Paper className={s.body}>
              {(isAuthorized || props.isAdmin) && (
                <>
                  {data.role === "parent" && (
                    <>
                      <h3>{Text.User.ParentsData}</h3>
                      <span>
                        <b>{Text.Headings.FullName}: </b>
                        {data.parentFio}
                      </span>
                      <span>
                        <b>{Text.Base.Gender.Self}: </b>
                        {data.parentSex}
                      </span>
                      <span>
                        <b>Email: </b>
                        {data.email}
                      </span>
                      <span>
                        <b>{Text.Base.PhoneNumber}: </b>
                        {data.phone}
                      </span>
                    </>
                  )}
                </>
              )}
              {(isAuthorized || props.isAdmin) && (
                <>
                  <h3>{Text.User.PersonalData}</h3>
                  {data.role !== "parent" && (
                    <>
                      <span>
                        <b>Email: </b>
                        {data.email}
                      </span>
                      <span>
                        <b>{Text.Base.PhoneNumber}: </b>
                        {data.phone}
                      </span>
                    </>
                  )}
                </>
              )}
              <span>
                <b>{Text.Geography.Country}: </b>
                {data.country}
              </span>
              <span>
                <b>{Text.Geography.City}: </b>
                {data.city}
              </span>
              <span>
                <b>{Text.Headings.Weight}: </b>
                {data.weight === "" ? noDataPlaceholder : data.weight}
              </span>
              <span>
                <b>{Text.Headings.Height}: </b>
                {data.height === "" ? noDataPlaceholder : data.height}
              </span>
              <span>
                <b>{Text.Event.SportsCategory}: </b>
                {data.category === "" ? noDataPlaceholder : data.category}
              </span>
              <span>
                <b>{Text.Event.SportsCertification}: </b>
                {data.certification === ""
                  ? noDataPlaceholder
                  : data.certification}
              </span>
            </Paper>
          </div>
          {data.role !== "manager" && (
            <>
              <div className={s.wrapper__other}>
                <Paper className={s.additional}>
                  {data.role !== "manager" && (
                    <>
                      <h3 className={s.title}>
                        {Text.User.UpcomingCompetitions}
                      </h3>
                      <div className={s.futureEvents}>
                        {data.future_events === "" ||
                        data.future_events === "[]"
                          ? Text.User.NoUpcomingCompetitions
                          : parseJSON({ json: data.future_events }).map(
                              (event) => (
                                <div
                                  key={nanoid()}
                                  className={s.futureEvents__item}
                                >
                                  <NavLink
                                    to={"/event/" + event.id}
                                    className={s.link}
                                  >
                                    {event.name} (
                                    {getDateLegacy(event.date_from)} -{" "}
                                    {getDateLegacy(event.date_to)})
                                  </NavLink>
                                  {getUserStatus({
                                    status: event.status,
                                    enableText: true,
                                  })}
                                  <span>
                                    {Text.Headings.Weight}: {event.weight}
                                  </span>
                                  <span>
                                    {Text.User.CurrentScore}: {event.score}
                                  </span>
                                  {event.price !== 0 &&
                                    event.hasOwnProperty("price") &&
                                    !isAdmin &&
                                    isAuthorized &&
                                    getPaymentBlock(event)}
                                </div>
                              )
                            )}
                      </div>

                      <br />

                      <h3 className={s.title}>
                        {Text.User.HistoryOfParticipation}
                      </h3>
                      <div className={s.history}>
                        {data.events === "" ? (
                          Text.User.NoHistory
                        ) : (
                          <EventsHistory eventsJSON={data.events} />
                        )}
                      </div>
                    </>
                  )}
                  {data.role === "trainer" && (
                    <>
                      <div className={s.learners}>
                        <h3 style={{ color: "var(--blue-color)" }}>
                          {Text.User.Students}
                        </h3>
                        {learners.length === 0 ? (
                          Text.User.NoStudents
                        ) : (
                          <LearnersList
                            setForceRerender={props.setForceRerender}
                            users={learners}
                            deleteLearner={props.deleteUserFromTrainer}
                            userID={userID}
                            isAuthorized={isAuthorized}
                          />
                        )}
                      </div>
                    </>
                  )}
                </Paper>
              </div>
            </>
          )}
        </div>
      )}

      {!isAdmin && (
        <ControlledModal
          isOpen={isOpenPayment}
          onClose={handleClosePayment}
          title={<h3>{Text.Payment.Payment}</h3>}
        >
          <RegistrationPayment />
        </ControlledModal>
      )}

      <ControlledModal
        isOpen={isOpenDocuments}
        title={<h3>{getDocsUpdationTitle()}</h3>}
      >
        <DocumentsUpdation
          sendDocs={props.secondConfirmation}
          isFetching={props.isFetching.docs}
          text={getDocsUpdationText()}
        />
      </ControlledModal>
    </div>
  );
};

export default User;
